.mobile-menu-toggle {
    display: block;
}

@media (min-width: 769px) {
    .mobile-menu-toggle {
        display: none;
    }
}

.mobile-menu {
    width: 100vw;
    height: 100vh;
    display: none;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999 !important;
    opacity: 0;
    box-shadow: 0px 29px 80px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
    transform: translateX(-100%);
    overflow-y: auto;
}

.mobile-menu-container {
    width: 60vw;
    height: 100vh;
    background-color: #9ec7c4ee;
    padding: 2rem;
}

.mobile-menu.active {
    opacity: 1;
    transform: translateX(0%);
}

.mobile-menu ul {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    list-style-type: none;
    margin: 0;
    padding: 0;
    align-items: flex-start;
}

.mobile-logo {
    width: 5rem;
    height: auto;
    cursor: pointer;
    position: relative;
    opacity: 1;
    transition: transform 0.3s ease, opacity 0.3s ease;
    z-index: 2;
}

@media (max-width: 769px) {
    .mobile-menu {
        display: block;
    }
}

.mobile-bm-pl {
  position: absolute;
  top: 1%;
  left: 1%;
  z-index: 1;
  width: 4em;
  height: 4em;
  margin-left: 1rem;
}

@keyframes logo-pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1) rotate(5deg);
    }
    100% {
      transform: scale(1);
    }
}

.mobile-logo:hover{
    opacity: 1;
    transform: scale(1.1) rotate(5deg);
    animation: logo-pulse 1s ease infinite;
}

.glow-text {
    font-size: 1.5rem; 
    font-weight: bold;
    display: inline-block;
    animation: glow 1.5s infinite alternate;
  }
  
  .no-underline::before {
    content: none; 
  }
  
  @keyframes glow {
    0% {
      text-shadow: 0 0 2px #ff9999, 0 0 4px #ff9999;
    }
    20% {
      text-shadow: 0 0 2px #ffcc99, 0 0 4px #ffcc99;
    }
    40% {
      text-shadow: 0 0 2px #ffff99, 0 0 4px #ffff99;
    }
    60% {
      text-shadow: 0 0 2px #99ff99, 0 0 4px #99ff99;
    }
    80% {
      text-shadow: 0 0 2px #99ffff, 0 0 4px #99ffff;
    }
    100% {
      text-shadow: 0 0 2px #9999ff, 0 0 4px #9999ff;
    }
  }