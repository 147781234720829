.panel3-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #f06, #4a90e2);
  }
  
  .question3-part {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    transition: transform 0.3s ease-in-out;
  }
  
  .question3-part:hover {
    transform: translateY(-5px);
  }
  
  /* Form Styling */
  .db3-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  /* Label Styles */
  .db3-form label {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    color: #333;
  }
  
  /* Span Styles for Label Text */
  .db3-form span {
    font-size: 0.95rem;
    color: #666;
    margin-bottom: 0.5rem;
  }
  
  /* Input Field Styling */
  .db3-form input {
    padding: 0.8rem;
    font-size: 1rem;
    border: 2px solid #ddd;
    border-radius: 6px;
    background-color: #f8f8f8;
    color: #333;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .db3-form input:focus {
    border-color: #4a90e2;
    box-shadow: 0 0 8px rgba(74, 144, 226, 0.5);
    outline: none;
  }
  
  /* Submit Button Styling */
  .db3-form button {
    background-color: #4a90e2;
    color: #fff;
    padding: 1rem 2rem;
    border: none;
    border-radius: 6px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .db3-form button:hover {
    background-color: #357ab7;
    transform: translateY(-2px);
  }
  
  .db3-form button:active {
    transform: translateY(0);
  }
  
  @media (max-width: 768px) {
    .question3-part {
      padding: 1.5rem;
      width: 90%;
    }
  
    .db3-form label {
      font-size: 0.9rem;
    }
  
    .db3-form input {
      font-size: 1rem;
    }
  
    .db3-form button {
      font-size: 1rem;
    }
  }
