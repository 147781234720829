.this-or-that-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 75vh;
  background-color: #ffffff; 
  border-radius: 8px; 
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 0 20px; 
  margin-top: 10px; 
}

/* Question Text Styles */
.this-or-that-question {
  font-size: 26px;
  margin-bottom: 20px;
  color: #333; 
  text-align: center; 
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

/* Options Container Styles */
.this-or-that-options {
  display: flex;
  flex-direction: row; 
  gap: 20px; 
  justify-content: center; 
  width: 100%;
  max-width: 800px;
}

/* Individual Option Label Styles */
.this-or-that-option-label {
  justify-content: flex-end;
  display: flex; 
  align-items: center; 
  position: relative; /* For positioning the image */
  background-color: #f0f8ff; /* Light blue background */
  border: 1px solid #ddd; 
  border-radius: 5px;
  padding: 15px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, transform 0.2s; /* Added transform transition */
  flex: 1; 
}

/* Option Image Styles */
.this-or-that-option-image {
  width: 60px; /* Set the width of the image */
  height: 60px; /* Set the height for a circle */
  border-radius: 50%; /* Make the image circular */
  position: absolute; /* Position the image absolutely */
  left: 15px; /* Positioning from the left */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for vertical centering */
  z-index: 1; /* Ensure the image is above the radio button */
}

/* Additional adjustment to ensure text is centered when image is present */
.this-or-that-option-label span {
  margin-left: 80px; /* Ensure there's space for the image */
  text-align: center;
}

/* Hover Effects for Option Labels */
.this-or-that-option-label:hover {
  background-color: #b0e0e6; /* Light cyan on hover */
  border-color: #87ceeb; /* Border color change on hover */
  transform: scale(1.05); /* Slightly scale up on hover */
}

/* Radio Button Styles */
input[type="radio"].this-or-that-radio {
  position: absolute; /* Position the radio button behind the image */
  left: 15px; /* Align with the image */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for vertical centering */
  opacity: 0; /* Hide the default radio button */
}

/* Active Option Styles */
input[type="radio"]:checked + .this-or-that-option-image {
  border: 3px solid #d7f81b; /* Orange border when selected */
}

.next-btn {
  background-color: #00b39b;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 30px;
    transition: background-color 0.3s ease;
    transform: skewX(-10deg);
}

.next-btn:hover {
  background-color: #2c859b;
  transform: skewX(-10deg) scale(1.05);
}

.this-or-that-option-label.correct {
  background-color: #d4edda;  /* Green */
  border-color: #c3e6cb;
  box-shadow: inset 0 0 10px rgba(0, 255, 0, 0.2); /* Add a soft green shadow */
}

.this-or-that-option-label.wrong {
  background-color: #f8d7da;  /* Red */
  border-color: #f5c6cb;
  box-shadow: inset 0 0 10px rgba(255, 0, 0, 0.2); /* Add a soft red shadow */
}

.this-or-that-question, .this-or-that-option-label {
  word-wrap: break-word; 
  hyphens: auto;
  box-sizing: border-box;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .this-or-that-container {
    padding: 15px;
    min-height: 68vh;
  }

  .this-or-that-question {
    font-size: 18px;
    margin-bottom: 25px;
  }

  .this-or-that-option-label {
    font-size: 0.9rem;
    justify-content: flex-end;
    text-align: right;
    padding-left: 60px;  /* Space for the image */
    max-width: 100%; /* Ensure the option labels don't stretch beyond the screen */
    flex: 1 1 auto;  /* Allow the labels to flex within the container */
    box-sizing: border-box; /* Ensure padding does not cause overflow */
    margin: 0; /* Remove any default margin that might push the element outside the screen */
  }

  .this-or-that-options {
    flex-direction: column; /* Stack options vertically on smaller screens */
    gap: 10px; /* Reduced gap */
    width: 100%; /* Ensure the container width is fully utilized */
  }

  .next-btn {
    font-size: 0.9rem;
    padding: 8px 16px;
    width: 70%; /* Full-width button on mobile */
  }

  .this-or-that-option-image {
    width: 50px;
    height: 50px;
  }
}

/* Box containing the previous questions list */
.previous-questions-box {
  margin-top: 30px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
}

/* Wrapper for the dates list */
.dates-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: flex-start;
}

/* Style for each date button */
.date-button {
  padding: 12px 20px;
  background-color: #f8f8f8;
  border: none;
  cursor: pointer;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 600;
  color: #333;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Hover effect for buttons */
.date-button:hover {
  background-color: #f0f0f0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

/* Style for selected (active) date button */
.date-button.selected {
  background-color: #49caa4;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.date-button:focus {
  outline: none;
}
