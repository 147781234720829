.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
  
.modal-content {
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    width: 90%;
    max-width: 500px;
}
 
  .modal-content h3 {
    margin-top: 0;
    text-align: center;
  }
    
  .modal-content h2 {
      margin-top: 0;
      margin-right: 2px;
      text-align: center;
      font-size: 1.6em;
      color: #47b1cc;
      position: relative;
      display: inline-block;
      text-shadow: 0 0 10px rgba(0, 255, 234, 0.8);
  }
  
  .modal-content p {
    margin: 10px 0;
  }
  
  .modal-close {
      position: absolute;
      top: 10px;
      right: 10px;
      margin-right: 10px;
      background: #e0e0e0;
      border: none;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 16px;
      color: #333;
      transition: background-color 0.3s ease, color 0.3s ease;
  }
    
  .modal-close:hover {
      background: #d0d0d0;
      color: #000;
  }
  
  .share-score, .modal-content button {
    background: #47b1cc;
    border: none;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .modal-content button:hover {
    background: #3da88b;
  }
  
  .streak-container {
      position: absolute;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
  }
  
  /*Share icons*/
  .share-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      border: 2px solid rgba(88, 135, 236, 0.3);
      border-left: none;
      border-right: none; 
      border-radius: 15px;
      background-color: rgba(255, 255, 255, 0.1); 
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.6);
      width: auto;
      margin: 20px auto;
      position: relative;
      animation: glowBorder 2s infinite alternate;
  }
  
  @keyframes glowBorder {
      from {
        border-color: rgba(255, 255, 255, 0.5);
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
      }
      to {
        border-color: rgba(0, 255, 255, 1);
        box-shadow: 0 0 20px rgba(0, 255, 255, 1);
      }
  }
  
/*Share icons container*/
.share-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 20px auto;
  color: #47b1cc;
  font-size: 2.2em;
  transition: color 0.3s ease;
}

.share-icons div {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.share-icons div:nth-child(1):hover {
  transform: scale(1.2);
}

.share-icons div:nth-child(2):hover {
  transform: scale(1.2);
}

.share-icons div:nth-child(3):hover {
  transform: scale(1.2);
}

.share-icons div:nth-child(4):hover {
  transform: scale(1.2);
}

/* Social Media Icons */
.social-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
}
  
.social-media-icons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px auto;
      transition: color 0.3s;
}

.social-button {
  color: #47b1cc;
  transition: transform 0.2s ease, color 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: transparent; 
  margin: 0 5px;
  overflow: hidden; 
}

.social-button:hover {
  transform: scale(1.1);
  color: #3da88b;
}

.social-icon {
  border-radius: 50%;
}

.previousDayQuestion {
    text-align: center;
    color:black;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
}

.previousDayQuestion .clickable {
    color: black;
    cursor: default;
    text-decoration: none;
    display: inline;
}
  
.previousDayQuestion .clickable:hover {
    color: #3da88b;
    cursor: pointer;
    text-decoration: underline;
}

.streak-alert-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.streak-alert-confetti {
  background-color: #ffffff;
  box-shadow: 4px 4px 4px 4px rgba(47, 145, 88, 0.5);
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 80px;
  border-radius: 1.4rem;
  z-index: 2000;
  pointer-events: none;
}

.streak-p {
  margin: 0;
  font-size: 1rem;
}

@media (min-width: 768px) {
  .modal-content {
    width: 70%;
  }
}

@media (min-width: 576px) {
  .modal-content {
    width: 80%;
  }
}

@media (min-width: 376px) and (max-width: 425px) {
  .modal-content h2 {
    font-size: 1.4em;
    padding: 0 10px;
    text-align: left; 
  }
  .modal-content {
    padding: 15px;
  }
}

@media (max-width: 376px) {
  .modal-content h2 {
    margin-top: 1rem;
    font-size: 1.4em;
    padding: 0 10px;
    text-align: left;
  }
  .modal-content {
    padding: 15px;
  }
}
