  .panel2-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #f06, #4a90e2);
  }
  
  /* Form Panel */
  .question2-part {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    transition: transform 0.3s ease-in-out;
  }
  
  .question2-part:hover {
    transform: translateY(-5px);
  }
  
  /* Form Styling */
  .db2-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  /* Label Styles */
  .db2-form label {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    color: #333;
  }
  
  /* Span Styles for Label Text */
  .db2-form span {
    font-size: 0.95rem;
    color: #666;
    margin-bottom: 0.5rem;
  }
  
  /* Input Field Styling */
  .db2-form input {
    padding: 0.8rem;
    font-size: 1rem;
    border: 2px solid #ddd;
    border-radius: 6px;
    background-color: #f8f8f8;
    color: #333;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .db2-form input:focus {
    border-color: #4a90e2;
    box-shadow: 0 0 8px rgba(74, 144, 226, 0.5);
    outline: none;
  }
  
  /* Submit Button Styling */
  .db2-form button {
    background-color: #4a90e2;
    color: #fff;
    padding: 1rem 2rem;
    border: none;
    border-radius: 6px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .db2-form button:hover {
    background-color: #357ab7;
    transform: translateY(-2px);
  }
  
  .db2-form button:active {
    transform: translateY(0);
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .question2-part {
      padding: 1.5rem;
      width: 90%;
    }
  
    .db2-form label {
      font-size: 0.9rem;
    }
  
    .db2-form input {
      font-size: 1rem;
    }
  
    .db2-form button {
      font-size: 1rem;
    }
  }
  
  /* Styles for react-datepicker navigation buttons */
.react-datepicker__navigation--next,
.react-datepicker__navigation--previous {
  font-size: 1.2rem; /* Adjust the font size as needed */
  line-height: 1; /* Ensure button alignment */
}

/* Optional: Adjust the padding or margin if needed */
.react-datepicker__navigation--next {
  margin-left: 8px;
}

.react-datepicker__navigation--previous {
  margin-right: 8px;
}