.dark-mode-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .dark-mode-switch:hover {
    background-color: #f0f0f0;
    transform: scale(1.05);
  }
  
  .dark-mode-switch:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba(0, 179, 155, 0.5);
  }
  
  .dark-mode-switch svg {
    width: 30px;
    height: 30px;
  }
  