.mainNav-wrapper{
  background: #ffff;
  background: linear-gradient(90deg,  #ffffff 0%,  #ddeff7 170%);
  padding: 0.5rem;
  position: sticky;
  top: 0;
  z-index: 30;
  backdrop-filter: blur(50px);
  height: 80px;  
  font-family:'Trebuchet MS', sans-serif;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-left-radius:500px;
  border-bottom-right-radius: 400px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mainNav-content {
  max-width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem;
  margin: 0 auto;
  height: 100%;
}

.logo {
  width: 7rem;
  height: auto;
  cursor: pointer;
  position: relative;
  z-index: 2;
  opacity: 1;
  margin-top: 1.1rem;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.svg-icon{
  max-width: 8.8rem;
  height: auto;
  opacity: 0.9;
}

.logo:hover{
  opacity: 1;
  transform: scale(1.1) rotate(5deg);
  animation: logo-pulse 1s ease infinite;
}

@keyframes logo-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1) rotate(5deg);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width:425px){
  .logo{
    width: 5.3rem;
  }
}