.hero-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly; /* Distribute evenly */
  align-items: center;
}

.top-category {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14vh; /* Keep margin top, you can adjust this */
  cursor: pointer;
}

.bottom-category {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4vh; /* Keep margin top, adjust as needed */
  cursor: pointer;
}

.category {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.category img {
  width: 15rem;
  height: 15rem; /* Set a consistent height to match the width */
  border-radius: 20%;
  margin-bottom: 1.2rem;
  object-fit: cover; /* Ensure consistent coverage and aspect ratio */
  transition: transform 0.3s ease; /* Smooth scaling on hover */
}

.category img:hover {
  transform: scale(1.1); /* Slightly reduced scale effect for smoother interaction */
}

.category span.text {
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: text;
}

.category-hidden {
  display: none;
}

/* For tablets and smaller screens (max-width: 768px) */
@media (max-width: 768px) {
  .top-category {
    margin-top: 12vh;  /* Adjust the space between top and bottom categories */
    margin-bottom: 2vh;  /* Reduce the gap here as well */
  }

  .bottom-category {
    margin-top: 3vh; /* Reduce the space between the categories */
    margin-bottom: 8vh; /* Reduced bottom gap for better alignment */
  }

  .category img {
    width: 10rem;
    height: 10rem; /* Maintain square aspect ratio */
  }
}

/* For mobile screens (max-width: 480px) */
@media (max-width: 480px) {
  .top-category {
    margin-top: 0vh; /* Remove top margin */
    margin-bottom: 0vh; /* Remove bottom margin */
  }

  .bottom-category {
    margin-top: -25vh; /* Pull the bottom category even closer */
    margin-bottom: 0vh; /* No bottom margin */
  }

  .category img {
    width: 8rem;
    height: 8rem; /* Maintain square aspect ratio */
  }
}

