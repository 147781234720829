/* Genel Konteyner Stilleri */
.game-container {
  text-align: center;
  margin-top: 20px;
  font-family: 'Trebuchet MS', sans-serif;
  min-height: calc(80vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
}

.wrong-answers-container {
  background-color: rgba(255, 255, 255, 0.7); /* Açık arka plan */
  padding: 15px;
  border-radius: 10px;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  justify-content: center;
  box-sizing: border-box;
}

.wrong-answers-container h3 {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
  width: 100%;
  text-align: center;
}

.wrong-answers-container ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.wrong-answers-container li {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  color: red;
  font-size: 16px;
  margin: 5px;
  white-space: nowrap;
}

.picture-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.whoami-calendar-modal {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-width: 350px;
  padding: 20px;
  background-color: #f4f4f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;  /* Ensure the modal is on top */
}

.whoami-calendar-container {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  z-index: 1;
  height: 40px;
  gap: 50px;
  width: auto;
}

.whoami-calendar-button {
  background: linear-gradient(90deg, #27b988 0%, #27a1aa 100%);
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.blurred-image:not(.blur) {
  filter: none; /* Remove blur when the 'blur' class is not present */
}

.whoami-calendar-button svg {
  margin-bottom: 2.5px;
}

.whoami-calendar-button:hover {
  background-color: #354b8a;
  transform: scale(1.05);
}

.whoami-calendar-container p {
  margin: 0;
  color: white;
  font-size: 1rem;
  text-align: center;
}

/* Bulanık Görüntü Konteyneri */
.blurred-image-container {
  position: relative;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 50px;
  max-width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.blurred-image {
  width: 100%;
  max-width: 100%;
  height: auto;
  filter: blur(12px);
  object-fit: contain;
  pointer-events: none;
}

.image-with-hints-container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.hint-container {
  position: absolute;
  top: 50%;
  right: -350px;
  transform: translateY(-50%);
  padding: 12px;
  text-align: left;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: auto;
  min-width: 220px;
  max-width: 350px;
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
}

.hint-container p {
  display: flex;
  align-items: baseline;
  gap: 5px;
  margin: 0;
  font-size: 16px;
  word-wrap: break-word;
  white-space: normal;
}

.hint-container strong {
  font-weight: bold;
  font-size: 18px;
}

.hint-container .hint-text {
  font-size: 14px;
}

.icons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.heart-container {
  display: flex;
  align-items: center;
}

.heart-container span {
  font-size: 1.5rem;
}

.options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
  max-width: 700px;
}

/* Seçenek Butonları */
.option-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #49caa4;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 200px;
  margin: 5px;
}

.option-btn:hover {
  background-color: #3da88b;
}

.correct-answer {
  font-size: 1.5rem;
  color: rgb(0, 0, 0);
  background-color: #d5faf0;
  text-align: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .game-container {
    padding: 15px;
  }

  .wrong-answers-container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }

  .wrong-answers-container h3 {
    font-size: 18px;
  }

  .wrong-answers-container li {
    font-size: 18px;
    padding: 4px 8px;
    margin: 3px;
  }

  .picture-container {
    flex-direction: column;
    align-items: center;
  }

  .blurred-image {
    width: 100%;
    height: auto;
  }

  .hint-container {
    position: relative;
    top: auto;
    right: auto;
    transform: none;
    width: 90%;
    margin-top: 15px;
    padding: 10px;
    text-align: left;
    font-size: 12px;
  }

  .icons-container {
    flex-direction: column;
    align-items: center;
  }

  .option-btn {
    width: 100%;
    font-size: 14px;
  }
}

/* Küçük Ekranlar İçin Özel Stil (480px ve altı) */
@media (max-width: 480px) {
  .game-container {
    padding: 10px;
    font-size: 14px;
  }

  .wrong-answers-container {
    width: 100%;
    max-width: 400px;
  }

  .wrong-answers-container li {
    font-size: 12px;
    padding: 4px;
    margin: 2px;
  }

  .option-btn {
    padding: 8px 15px;
    font-size: 12px;
  }

  .hint-container {
    width: 100%;
    padding: 8px;
    font-size: 12px;
  }

  .hint-container p {
    word-wrap: break-word;
    font-size: 16px;
  }
}
