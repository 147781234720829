.nav-wrapper {
  background: #ffff;
  background: linear-gradient(90deg,  #ffffff 0%,  #1a6fa0 230%);
  padding: 0.5rem;
  position: sticky;
  top: 0;
  z-index: 30;
  backdrop-filter: blur(50px);
  height:80px;
  box-shadow: rgb(241, 215, 250) 2px 2px;
  font-family: 'Trebuchet MS', sans-serif;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-left-radius:500px;
  border-bottom-right-radius: 400px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-content {
  max-width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem;
  margin: 0 auto;
  height: 100px;
}

.nav-content ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-content li {
  margin: 0 0.5rem;
}

.menu-item {
  text-decoration: none;
  display:  flex;
  font-size: 1.6rem;
  font-weight: 500;
  color: #ffffff;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: color 0.3s ease;
  height: auto;
  margin-bottom: 1.5rem;
}

.menu-item img{
  width: 2rem;
  margin-right: 0.5rem;
}

.menu-item::before {
  content: " ";
  width: 2rem;
  height: 0.2rem;
  background: linear-gradient(90deg, #54a8f7 0%, #f89393 100%);
  border-radius: 0.5rem;
  position: absolute;
  bottom: -0.6rem;
  opacity: 0;
  transform: translateX(-1.5rem);
  transition: all 0.3s ease;
}

.menu-item:hover {
  color: #228da8;
}

.menu-item:hover::before {
  width: 100%;
  transform: translateX(0);
  opacity: 1;
}

a {
  font-size: 0.9rem;
  font-weight: 450;
  display: table-row;
  align-items: center;
  justify-content: center;
  color: #6ccde6;
  padding: 0.6rem 1rem;
  border: none;
  outline: 1.5px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease;
}

.menu-btn {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 0.4rem;
  color: #fff;
  background: linear-gradient(90deg, #86d1e4 -0.13%, #8ff7e1 99.87%);
  line-height: 0;
  cursor: pointer;
  transition: all 0.4s ease;
  display: none;
  margin-bottom: 1em;
}

.menu-btn:hover {
  color: #67ccc4;
  background: #ffffff;
  border: 1px solid #77b8af;
}

.glow-text {
  font-size: 1.5rem; 
  font-weight: bold;
  display: inline-block;
  animation: glow 1.5s infinite alternate;
}

.no-underline::before {
  content: none; 
}

@keyframes glow {
  0% {
    text-shadow: 0 0 2px #ff9999, 0 0 4px #ff9999;
  }
  20% {
    text-shadow: 0 0 2px #ffcc99, 0 0 4px #ffcc99;
  }
  40% {
    text-shadow: 0 0 2px #ffff99, 0 0 4px #ffff99;
  }
  60% {
    text-shadow: 0 0 2px #99ff99, 0 0 4px #99ff99;
  }
  80% {
    text-shadow: 0 0 2px #99ffff, 0 0 4px #99ffff;
  }
  100% {
    text-shadow: 0 0 2px #9999ff, 0 0 4px #9999ff;
  }
}

.logo-navbar {
  width: 7rem;
  height: auto;
  cursor: pointer;
  position: relative;
  z-index: 2;
  opacity: 1;
  margin-bottom: 1.2rem;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.logo-navbar:hover{
  opacity: 1;
  transform: scale(1.1) rotate(5deg);
  animation: logo-pulse 1s ease infinite;
}

@keyframes logo-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1) rotate(5deg);
  }
  100% {
    transform: scale(1);
  }
}

body.dark-mode { 
  background-color: #121212; 
  color: #ffffff; } 

  .nav-wrapper.dark-mode { 
    background: linear-gradient(90deg, #1f1f1f 0%, #000000 230%); color: white; 
  } 
  .menu-item.dark-mode { 
    color: #ffffff; 
  } 
  .menu-btn.dark-mode { 
      background: #333; 
      color: #ffffff; 
  }

@media (max-width: 1325px) {
  .nav-wrapper {
      padding: 0 2rem;
  }
}

@media (max-width: 1024px) and (max-height: 824px) {
  .logo-navbar {
    width: 5rem;
    height: auto;
    margin-left: 0;
    margin-right: auto;
    position: relative;
    left: -1rem;
  }
  .menu-item{
    font-size: 1.3rem;
  }
}

@media (max-width: 824px) {
  .menu-btn {
      display: block;
  }
  .logo-navbar{
    width: 5rem;
    height: auto;
    margin-left: 0;
    margin-right: auto;
    position: relative;
    left: -1.5rem;
  }
  .nav-content ul {
      display: none;
  }
}

@media (max-width: 425px) {
  .logo-navbar{
    width: 5.3rem;
    right: 40%;
    margin-bottom: 1.8rem;
  }
}

@media (max-width: 320px) {
  .logo-navbar {
    width: 4.5rem;
    margin-bottom: 1.9rem;
  }
}