/* General Reset and Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  width: 100%;
  margin: 0;
  padding: 0;
}

.navbar, .footer {
  margin: 0;
  padding: 0;
  width: 100%;
}

/* Main Container */
.title-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align items to the top */
  width: 100%;
  max-width: 800px;
  padding: 20px;
  min-height: 100vh;
  margin: 0 auto;
}

/* Title List */
.title-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  margin-bottom: 90px;
  justify-content: center;
  text-align: center;
}

/* Title Button */
.title-button {
  background: linear-gradient(90deg, #e6fdcc 0%, #e0fdff 100%);
  color: #000000;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1.1rem;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.title-button:hover {
  background-color: #49caa4;
  color: rgb(0, 0, 0);
  transform: translateY(-3px);
}

.title-button.selected {
  background-color: #49caa4;
  color: white;
  font-weight: bold;
}

.title-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

/* Search and Input Styling */
.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 100%;
  margin-bottom: 20px; /* Space between search input and results */
}

.search-input {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 30px;
  border: 1px solid #ccc;
  outline: none;
  transition: all 0.3s ease;
  width: 250px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.search-input:focus {
  border-color: #49caa4;
  box-shadow: 0 4px 12px rgba(76, 175, 80, 0.3);
}

/* Search Results Container */
.search-results {
  width: 100%;
  margin-top: 20px; /* Add space between search bar and results */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 15px; /* Space between search results */
}

/* Pagination Styling */
.pagination {
  position: sticky; 
  bottom: 20px; 
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background-color: white; /* Ensure background visibility */
  z-index: 10; /* Makes sure it's above other content */
}

.pagination button {
  background-color: #49caa4;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.pagination button:hover {
  background-color: #7db0f7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pagination button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .title-list {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  .title-button {
    font-size: 1rem;
    width: 100%;
    padding: 12px;
  }
  .search-input {
    width: 180px;
  }
  .search-container {
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
}

@media (max-width: 480px) {
  .title-list-container {
    padding: 10px;
  }

  .title-list {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .search-input {
    width: 100%;
  }

  .title-button {
    padding: 12px 20px;
    font-size: 1rem;
  }
}
