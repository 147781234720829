.contact-page {
  background-image: url("./contactus.png");
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .contact-page {
    background-size: 100%;
    background-position: center;
  }
}

@media (max-width: 576px) {
  .contact-page {
    background-size: cover;
    background-position: center;
  }
}

.contact-form-container {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 90%;
  margin-left: auto;
  margin-right: 150px;
  animation: fadeInUp 1s ease;

  @media (max-width: 1200px) {
    margin-right: 100px;
  }

  @media (max-width: 992px) {
    margin-right: 50px;
  }

  @media (max-width: 768px) {
    margin-right: 20px;
    width: 80%;
  }

  @media (max-width: 576px) {
    margin-right: 10px;
    width: 90%;
  }

  @media (max-width: 400px) {
    margin-right: 5px;
    width: 95%;
  }
}

.contact-form {
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
}

.contact-form h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #0870a0;
  text-align: center;
}

.contact-form label {
  display: block;
  margin-bottom: 10px;
  color: #20917e;
  font-weight: bold;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.contact-form textarea {
  height: 100px;
  resize: vertical;
}

.contact-form button {
  background-color: #106c83;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #0ca778;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}