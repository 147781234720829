/* Ensure the HTML and body take full height */
html, body {
  height: 100%;
  margin: 0;
}

/* Flex container to make sure it grows to fill the viewport */
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full height of the viewport */
}

/* Flex item that grows to take available space */
.content {
  flex: 1; /* Grow to take up available space */
}

/* Adjust footer CSS */
.footer {
  font-size: 0.8rem;
  text-align: center;
  background: linear-gradient(90deg, #b4b2e0ab 0%, #b6fcf8 100%);
  padding: 2rem;
  font-family: 'Trebuchet MS', sans-serif;
  min-height: 150px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: auto; /* Push footer to bottom of container */
}

.footer-item {
  display: inline-block;
  padding: 1rem;
  color: rgb(0, 0, 0);
  margin-right: 20px;
  height: auto;
  font-size: 1rem;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

a {
  text-decoration: none !important;
}

.footer-item:hover {
  background-color: rgb(165, 219, 255);
  border-radius: 2rem;
  transform: translateY(-3px);
}

.sb_footer-links-div {
  display: inline-block;
  text-align: center;
}

.footer-company-name {
  font-size: 1em;
  margin-top: 0.9rem;
  font-weight: bold;
  color: #333;
}

.footer-media-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-icons:hover {
  transform: translateY(-3px);
}

@media (min-width: 1440px) {
  .footer {
    margin-top: 0; /* Remove margin-top for screens this large */
  }
}

/* Adjustments for tablets and medium-sized screens */
@media (min-width: 992px) {
  .footer {
    margin-top: 6vh; /* Adjust margin for medium-sized screens */
  }
}

@media (max-width: 900px) {
  .footer {
    height: auto;
    padding: 1rem;
    margin-top: 4vh; /* Adjust margin for medium screens */
  }
  .footer-item {
    display: block;
    margin: 0.5rem 0;
    padding: 0.5rem;
  }
  .sb_footer-links-div {
    text-align: center;
  }
}

@media (max-width: 600px) {
  .footer {
    font-size: 0.7rem;
    padding: 0.5rem;
    margin-top: 0;
  }

  .footer-item {
    margin: 0.3rem 0;
    padding: 0.3rem;
  }
}
