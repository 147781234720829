.form{
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: flex-start;
    border-radius: 5px;
    padding: 30px;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.7);
}

.form input[type=text], input[type=password]{
    margin-top: 0.5rem;
    font-size: 1rem;
    width: 300px;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 0.5rem;
}

.button{
    margin-top: 0.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    padding: 0.5rem;
    background: rgb(133, 72, 141);
    color: white;
    cursor: pointer;
    width: 45%;
}

.form label{
    font-size: small;
    margin-top: 0.5rem;
}
.form p{
    color: blue;
}
.error{
    color: red;
}
.success{
    color:green;
}