.panel-container{
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question-section{
    width: 600px;
    padding: 20px;
    background-color: #2a54adc4;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(13, 25, 133, 0.267);
}

.question-section form{
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.question-section label{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.question-section label span{
    min-width: 120px;
    text-align: right;
    padding-right: 10px;
}

.question-section label input,
.question-section label textarea,
.question-section label select{
    flex: 1;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.question-section label textarea{
    height: 100px;
    resize: vertical;
}

.question-section button{
  background-color: #106c83;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.question-section button:hover{
    background-color: #0e566b;
}

/* Styles for react-datepicker navigation buttons */
.react-datepicker__navigation--next,
.react-datepicker__navigation--previous {
  font-size: 1.2rem; /* Adjust the font size as needed */
  line-height: 1; /* Ensure button alignment */
}

/* Optional: Adjust the padding or margin if needed */
.react-datepicker__navigation--next {
  margin-left: 8px;
}

.react-datepicker__navigation--previous {
  margin-right: 8px;
}
