body {
    font-family:'Trebuchet MS', sans-serif;
    transition: filter 0.3s ease; 
}

.lives-container button{
    text-align: center;
    color: black;
    background: transparent;
    cursor: pointer;
    font-size: 18px;
    border-radius: 0.8rem;
    border-color: #ffef0f;
    margin-left:250px;
}

.heart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
.heart-container span {
    font-size: 25px; 
}

.reveal-all-btn{
    background-color: #ffef0f;
    color: black;
    border: none;
    padding: 10px 20px;
    width: 100%;
    max-width: 185px;
    border-radius: 0.8rem;
    cursor: pointer;
    margin-top: 20px;
    text-align: center;
    margin-bottom: 15px;
}

.input-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    margin: 20px auto;
    max-width: 500px;
}

.autosuggest-container {
    position: relative;
    width: 100%;
}
  
.autosuggest-suggestions-container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    background-color: #fff;
    border-top: none;
    border-radius: 0 0 5px 5px;
    z-index: 1;
}

.autosuggest-suggestion {
    padding: 10px;
    cursor: pointer;
}
  
.autosuggest-suggestion:hover {
    background-color: #bffadc;
}

.alert-container {
    position: fixed; /*or fixed*/
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
  
.alert {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    animation: slide-up 0.5s ease-out;
}

.answer-progress-container {
    display: flex;
    align-items: center;
    gap: 20px;
}

.answer-progress {
    flex: 1;
    background-color: #97cce6d8;
    border-radius: 5px;
}

.answer-progress-bar {
    width: 0;
    height: 20px;
    background-color: #49caa4;
    border-radius: 5px;
    text-align: center;
    color: white;
    line-height: 20px; 
    font-size: 14px;
    transition: width 0.5s;
}

.heart-animation {
    animation: shake 0.5s ease-in-out;
}
  
.heart-animation-alert {
    animation: shake 0.5s ease-in-out, color-change 0.5s alternate infinite;
}  
  
@keyframes color-change {
    0% { color: red; }
    100% { color: inherit; }
}
  
@keyframes boxAlert {
    0% { background-color: inherit; }
    50% { background-color: red; }
    100% { background-color: inherit; }
}
  
.box-alert-animation {
    animation: boxAlert 1.5s ease-in-out;
}
  
.highlight-green-animation {
    animation: highlight-green 1s ease-in-out forwards;
}
  
@keyframes slide-up {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
}
  
@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px) rotate(-5deg); }
    50% { transform: translateX(5px) rotate(5deg); }
    75% { transform: translateX(-5px) rotate(-5deg); }
    100% { transform: translateX(0); }
  }

@keyframes highlight-green {
    0% { background-color: #e6fdcc; }
    50% { background-color: #d6f9d3; }
    100% { background-color: #e6fdcc; }
}

.help-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.help-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    margin: 20px;
}

.help-list {
    padding-left: 20px;
    margin-top: 10px;
    text-align: left;
}

.help-list li {
    margin-bottom: 5px;
    position: relative;
}

.help-list li::before {
    content: "•";
    color: #00b39b;
    font-weight: bold;
    position: absolute;
    left: -20px;
}

.help-modal .help-content .help-p {
    font-size: 1.1em;
    color: #333;
    text-align: center;
    margin: 10px 0 15px;
}

.question-mark-button {
    cursor: pointer;
    display: flex;
    font-size: 34px;
    background: linear-gradient(90deg, #27b988 0%, #27a1aa 100%);
    color: white;
    border: none;
    border-radius: 30%;
    padding: 15px 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, transform 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.question-mark-button:hover {
    background-color: #008f7d;
    transform: scale(1.05);
}

.question-mark-button:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba(0, 179, 155, 0.5);
}

.close-btn {
    cursor: pointer;
    background-color: #00b39b;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    padding: 10px;
}

.soccer-calendar-modal {
  position: absolute;  /* Mutlak konumlandırma */
  top: 50%;  /* Dikey ortalama */
  left: 50%;  /* Yatay ortalama */
  transform: translate(-50%, -50%);  /* Tam ortalamayı sağlamak için */
  width: auto;
  max-width: 350px;
  padding: 20px;
  background-color: #f4f4f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.soccer-calendar-container {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  z-index: 1;
  height: 40px;
  gap: 50px;
  width: auto;
}

.soccer-calendar-button {
  background: linear-gradient(90deg, #27b988 0%, #27a1aa 100%);
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.soccer-calendar-button svg {
  margin-bottom: 2.5px;
}

.soccer-calendar-button:hover {
  background-color: #354b8a;
  transform: scale(1.05);
}

.soccer-calendar-container p {
  margin: 0;
  color: white;
  font-size: 1rem;
  text-align: center;
}

/* Takvim Arayüzü Stilleri */
.react-calendar {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f4f4f9;
  width: 100%;
  padding: 10px;
}

/* Takvim Butonları ve Hover Efekti */
.react-calendar__tile {
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.react-calendar__tile:hover {
  background-color: #27b988;
  color: rgb(0, 0, 0);
}

/* Takvim Ayarları */
.react-calendar__navigation button {
  background-color: #27b988;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.react-calendar__navigation button:hover {
  background-color: #218b7c;
}

.react-calendar__navigation button:disabled {
  background-color: #e0e0e0;
  color: #27b988;
  cursor: not-allowed;
}

.category-container{
    text-align: center;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
}

.category-container h2 {
    font-size: 1.3rem;
    text-align: center;
    padding: 5px;
}

.quiz-box {
    padding: 9px;
    font-size: 20px;
    background: linear-gradient(90deg, #e6fdcc 0%, #e0fdff 100%);
    border-radius: 0.8rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    margin: 10px auto;
    width: 350px; 
    max-width: none; 
    text-align: center; /* Centers text horizontally */
    display: flex; /* Use Flexbox */
    align-items: center; /* Center text vertically */
    justify-content: center; /* Center text horizontally */
    height: 38px; /* Adjust height as needed */
    overflow-wrap: break-word; 
}

.quiz-input {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
}

.quiz-input.hidden {
    display: none;
}

.quiz-input input[type="text"]  {
    padding: 8px;
    font-size: 20px;
    border-radius: 0.8rem;
    margin: 10px auto;
    text-align: center;
    width: 370px;
    box-sizing: border-box;
}

.quiz-input input[type="text"]:focus{
    outline:4px solid #7db0f7; 
}

.quiz-progress-bar-container {
    width: 20%;
    max-width: 360px;
    background-color: #97cce6d8;
    border-radius: 5px;
    margin: 10px auto;
}
  
.quiz-progress-bar {
    width: var(--progress-width);
    height: 20px;
    background-color: #49caa4;
    border-radius: 5px;
    text-align: center;
    color: white;
    line-height: 20px; 
    font-size: 14px;
    transition: width 0.5s;
}
  
@media (min-width:1441px) {
  .reveal-all-btn {
    max-width: 330px;
    font-size: 20px;
  }
}

@media (min-width: 969px) and (max-width: 1440px) {
  .quiz-progress-bar-container {
    width: 25.5%;
    max-width: none;
  }
  .category-container h2 {
    font-size: 26px;
  }
  .lives-container button {
    margin-left: 250px;
  }
  .reveal-all-btn {
    max-width: 320px;
    font-size: 16px;
  }
}
  
  @media (min-width: 824px) and (max-width: 1024px) {
    .lives-container button {
      margin-left: 250px;
    }
    .quiz-input input[type="text"] {
      width: 370px;
    }
    .quiz-progress-bar-container {
      width: 35%;
      max-width: none;
    }
    .reveal-all-btn {
      max-width: 320px;
      font-size: 16px;
    }
  }
  
  @media (min-width:768px) and (max-width:823px){
    .reveal-all-btn {
      max-width: 300px;
    }
  }
  
  @media (max-width: 768px) {
    .quiz-input input[type="text"] {
      width: 530px;
    }
    .quiz-box {
      width: 70%;
      padding: 2px;
      height: 30px;
      overflow: hidden;
    }
    .quiz-progress-bar-container {
      width: 70%;
      max-width: none;
    }
    .category-container h2 {
      font-size: 22px;
    }
    .reveal-all-btn {
      font-size: 20px;
      padding: 8px 0;
    }
    .help-content {
      padding: 15px;
    }
    .close-btn {
      font-size: 16px;
      padding: 8px;
    }
  
    /* Make sure the calendar is unaffected by the media query */
    .react-calendar {
      max-width: 100% !important; /* Ensure calendar fills the available space */
      font-size: 14px !important; /* Adjust font size for mobile */
    }
  }
  
  @media (min-width: 320px) and (max-width: 425px) {
    .lives-container button {
      margin-left: 55px;
    }
    .quiz-input input[type="text"] {
      width: 240px;
    }
    .category-container h2 {
      font-size: 20px;
    }
    .reveal-all-btn {
      font-size: 18px;
      padding: 6px 0;
    }
    .heart-container span {
      font-size: 18px;
    }
    .soccer-calendar-button {
      font-size: 0.2rem;
      padding: 8px 16px;
      border-radius: 15px;
      transform: scale(0.9);
    }
    .soccer-calendar-button svg {
      margin-bottom: 2px;
    }
    .question-mark-button {
      font-size: 22px;
    }
    .soccer-calendar-container {
      gap: 3px;
    }
  }
  
  @media (min-width: 321px) and (max-width:375px) {
    .quiz-input input[type="text"] {
      width: 230px;
    }
    .lives-container button {
      margin-left: 35px;
    }
    .category-container h2 {
      font-size: 22px;
    }
    .heart-container span {
      font-size: 18px;
    }
    .soccer-calendar-button {
      font-size: 0.4rem;
      padding: 8px 16px;
      border-radius: 15px;
      transform: scale(0.9);
    }
    .soccer-calendar-button svg {
      margin-bottom: 2px;
    }
    .question-mark-button {
      font-size: 24px;
    }
    .soccer-calendar-container {
      gap: 15px;
    }
  }
  
  @media(max-width: 600px) {
    .lives-container button {
      margin-left: 17px;
    }
    .quiz-input input[type="text"] {
      width: 200px;
    }
    .category-container h2 {
      font-size: 18px;
    }
    .reveal-all-btn {
      font-size: 18px;
      padding: 6px 0;
    }
    .quiz-box {
      font-size: 16px;
    }
  }
  